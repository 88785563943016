import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/js/utils";

export default class Intl {
    constructor(options) {
        this.input = options.phone_input;
        this.hidden_input = options.hidden_input;

        this.isAttached = false;
        this.attached();
    }

    attached() {
        if(this.isAttached) return;
        if(document.body.contains(this.input) == false || document.body.contains(this.hidden_input) == false) return;

        this.attachUI();
        this.attachEvent();
        this.isAttached = true
    }

    attachUI() {
        this.iti = intlTelInput(this.input, {
            initialCountry: "auto",
            autoPlaceholder: 'aggressive',
            separateDialCode: true,
            formatOnDisplay: true,
            // hiddenInput: this.hidden_input,
            customPlaceholder:  (selectedCountryPlaceholder, selectedCountryData) => {
                return "e.g. " + selectedCountryPlaceholder;
            },
            geoIpLookup: function(callback) {
              fetch("https://ipapi.co/json")
                .then(function(res) { return res.json(); })
                .then(function(data) { callback(data.country_code); })
                .catch(function() { callback("jp"); });
            }
          });
          
        if(this.hidden_input.value) {  
            this.iti.setNumber(this.hidden_input.value);
        }
    }

    attachEvent(){
        const _this = this;
        // Listen to the keyup event on the input field
        _this.input.addEventListener('keyup', function () {
            // Get the full phone number
            const fullNumber = _this.iti.getNumber(intlTelInputUtils.numberFormat.E164);
            // Set the value of the hidden input field
            _this.hidden_input.value = fullNumber;
        });
    }
}