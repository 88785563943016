
export default class EmailSend {
    constructor(form, messageForm) {
        this.form = form;
        this.messageForm = messageForm;
        this.isAttached = false;

        this.attach();
    }

    attach() {
        // if (this.isAttached) return;
        if(this.form) {
            this.action = this.form.action;
            this.submit = this.form.querySelector('button[type="submit"]');
            this.isAttached = true;
        } else {
            return;
        }

        this.attachEvent();
    }

    attachEvent() {
        let _this = this;

        _this.form.addEventListener('submit', (e) => {
            e.preventDefault();
            const formData = new FormData(_this.form);

            _this.submit.classList.add('disabled');
            _this.submit.disabled = true;

            // Submit the form using fetch
            fetch(_this.action, {
                method: 'POST',
                body: formData
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                _this.messageForm.classList.remove("show");
                setTimeout(function () {
                    _this.messageForm.style.visibility = "hidden";
                }, 300); 
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
        })
    }
}