import { validateImageFile } from "./utils";
import Validator from "./validation";

export default class FormSubmitHandler {
    constructor(option) {
        this.isAttached = false;
        this.option = option;
        this.redirect = option.success_redirect;
        this.pictures = [];

        this.attach();
    }

    attach() {
        if (this.isAttached) return;

        this.load_button = document.querySelector(this.option.load_selector);
        this.reset_button = document.querySelector(this.option.reset_selector);
        this.input_file = document.querySelector(this.option.input_selector);
        this.form = document.querySelector(this.option.form_selector);
        this.grid = document.querySelector(this.option.grid_selector);
        
        if(this.option.validation) {
            this.validation = this.option.validation
        }


        if (this.load_button && this.input_file && this.form && this.grid) {
            this.isAttached = true;
        } else {
            return;
        }

        this.attachEvent();
    }

    attachEvent() {
        let _this = this;

        _this.load_button.addEventListener('click', (e) => {
            e.preventDefault();
            this.input_file.click();
        });


        _this.input_file.addEventListener('change', async(e) => {
            const selectedFiles = e.target.files;

            if(selectedFiles) {
                for(const file of selectedFiles) {
                    await validateImageFile(file)
                        .then(() => {
                            var item = _this.addImage(file);

                            if(item) {
                                const closeItem = item.querySelector('.btn');

                                closeItem.addEventListener('click', (e) => {
                                    const itemId = e.currentTarget.getAttribute('data-item');
                                    const filename = e.currentTarget.getAttribute('data-name');

                                    const element = _this.grid.querySelector(`#${itemId}`);
                                    if(element) {
                                        element.remove();
                                    }

                                    const index = _this.pictures.findIndex(item => item.name === filename);
                                    if (index !== -1) {
                                        _this.pictures.splice(index, 1);
                                    }

                                });
                            }
                        })
                        .catch(() => {
                            //
                        })
                }
            }
            _this.input_file.value = null;
        });

        _this.form.addEventListener('submit', (e) => {
            e.preventDefault();
            this.clearErrors();

            const url = new URL(_this.form.action);
            
            const data = new FormData(_this.form);
            const token = _this.form.querySelector('input[name="_token"]');
            if(!token) {
                throw new Error('CSRF-TOKEN token hidden input is required');
            }

            _this.pictures.forEach((file, _) => {
                data.append('pictures[]', file);
            });


            if(_this.validation) {
                const item = Object.fromEntries(
                    Object.keys(_this.validation).map(key => [key, data.get(key)])
                );

                const validator = new Validator(item, _this.validation);

                if(validator.validate()) {
                    _this.submitForm(url, data, token.value);
                } else  {
                    this.setError(validator.errors);
                }
    
            } else {
                _this.submitForm(url, data, token.value);
            }
        });


        _this.reset_button.addEventListener('click', () => {
            _this.input_file.value = "";
            _this.pictures.length = 0;
            _this.grid.innerHTML = "";
        });
    }

    submitForm(url, data, csrf) {
        fetch(url.pathname, {
            method: 'POST',
            body: data,
            headers: {
                'X-CSRF-TOKEN': csrf
            }
        })
        .then(response => response.json())
        .then(data => {
            // Check if the response has a validation error
            if (data.hasOwnProperty('errors')) {
                // Handle validation errors, for example, display them in the UI
                // console.error('Validation Error:', data.errors);
                this.setError(data.errors);
                
            } else {
                // If the response is successful (status code 200), redirect to another page
                window.location.href = this.redirect;
            }
        })
        .catch(error => {
            console.error('Fetch Error:', error);
        });
    }

    clearErrors() {
        const _this = this;
    
        // Remove any existing error messages and styling
        const errorElements = _this.form.querySelectorAll('.alert.alert-danger');
        errorElements.forEach(element => {
            element.remove();
        });
    
        // Remove the has-error class from all form groups
        const formGroups = _this.form.querySelectorAll('.form-group');
        formGroups.forEach(formGroup => {
            formGroup.classList.remove('has-error');
        });
    }

    setError(errors) {
        const _this = this;

        Object.keys(errors).forEach(field => {
            const messages = errors[field];

            if(field.startsWith('pictures') || field == 'primary_image') {
                var alert = document.createElement('div');
                var paragraph = document.createElement('p');
                alert.className = 'alert alert-danger';
                paragraph.textContent = messages;
                alert.appendChild(paragraph);
                _this.form.insertBefore(alert, _this.form.firstChild);
    
            } else {

                const inputElement = _this.form.querySelector(`[name="${field}"]`);
            
                if(inputElement) {
                    // Find the invalid-feedback span next to the input
                    const invalidFeedback = inputElement.parentElement.querySelector('.invalid-feedback');
            
                    // Update the span text with the first error message
                    if (invalidFeedback) {
                        invalidFeedback.querySelector('span').textContent = messages[0];
            
                        // Find the parent form-group and add the has-error class
                        const formGroup = inputElement.closest('.form-group');
                        if (formGroup) {
                            formGroup.classList.add('has-error');
                        }
                    }
                }

            }
        });
        
    }

    addImage(file) {
        let _this = this;

        const id = new Date().getTime();
        const item = document.createElement("div");
        item.id = `item_${id}`;
        item.classList.add("picture-grid-item")
        item.innerHTML = `
            <img src="${URL.createObjectURL(file)}"/>
            <a class="btn btn-icon btn-sm btn-danger" data-item="item_${id}" data-name="${file.name}">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M18 6l-12 12"></path>
                    <path d="M6 6l12 12"></path>
                </svg>
            </a>
            <div class="form-checkbox">
                <input type="radio" class="form-check-input" name="primary_image" value="${file.name}"/>
                <label class="form-check-label">PRIMARY</label>
            </div>
        `

        _this.grid.appendChild(item);
        _this.pictures.push(file);

        return item;
    }
}