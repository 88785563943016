export default class DataFetcher
{
    constructor(option) {
        this.selector = option.selector;

        this.dataFetchedEvent = new CustomEvent('dataFetched', {
            bubbles: true,
            detail: {
                data: null,
                error: null
            }
        });

        this.attach();
    }

    attach() {

        if (this.isAttached) return;
        this.selector = document.querySelector(this.selector);

        if(this.selector) {
            this.isAttached = true;
        }

        this.attachEvent();
    }

    attachEvent() {
        let _this = this;

        _this.selector.addEventListener('change', (event) => {
            const url = event.target.value
            fetch(url)
                .then((response) => {
                    if(!response.ok){
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    _this.dataFetchedEvent.detail.data = data;
                    _this.dataFetchedEvent.detail.error = null;
                })
                .catch((error) => {
                    _this.dataFetchedEvent.detail.data = null;
                    _this.dataFetchedEvent.detail.error = error.message;
                })
                .finally(() => {
                    _this.selector.dispatchEvent(_this.dataFetchedEvent);
                });
        });
    }
}