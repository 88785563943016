export default class Tab {
    constructor(target) {
        this.isAttached = false;
        this.target = target;

        this.attach();
    }

    attach() {
        if (this.isAttached) return;

        this.element = document.querySelector(this.target);

        if (this.element) {
            this.attachUI();
            this.setActiveTabFromLocalStorage();
            this.isAttached = true;
        }
    }

    attachUI() {
        const tabs = this.element.querySelectorAll(".tab");

        tabs.forEach(tab => {
            tab.addEventListener('click', (event) => {
                const targetTab = event.currentTarget;
                this.setActiveTab(targetTab);
            });
        });
    }

    setActiveTab(targetTab) {
        const activeTab = this.element.querySelector('.tab.active');
        const activeContent = this.element.querySelector('.tab-panel.active');
        const targetContent = this.element.querySelector(`#${targetTab.getAttribute('data-tab')}`);

        if (targetTab !== activeTab) {
            if (activeTab) {
                activeTab.classList.remove('active');
            }
            if (activeContent) {
                activeContent.classList.remove('active');
            }

            targetTab.classList.add('active');
            targetContent.classList.add('active');

            // Save the active tab to local storage
            localStorage.setItem('activeTab', targetTab.getAttribute('data-tab'));
        }
    }

    setActiveTabFromLocalStorage() {
        const activeTabId = localStorage.getItem('activeTab');
        const activeTab = this.element.querySelector(`.tab[data-tab="${activeTabId}"]`);
        const activeContent = this.element.querySelector(`#${activeTabId}`);

        if (activeTab && activeContent) {
            this.setActiveTab(activeTab);
        } else {
            // Fallback to the first tab if the stored tab does not exist
            const firstTab = this.element.querySelector('.tab');
            if (firstTab) {
                this.setActiveTab(firstTab);
            }
        }
    }
}
