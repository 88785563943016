export default class Validator {
    constructor(data, rules) {
        this.data = data;
        this.rules = rules;
        this.errors = {};
    }

    validate() {
        for (const [field, fieldRules] of Object.entries(this.rules)) {
            if (!Object.prototype.hasOwnProperty.call(this.data, field)) {
                continue;
            }

            const fieldValue = this.data[field];

            for (const rule of fieldRules.split("|")) {
                const [ruleName, ...ruleArgs] = rule.split(":");

                if (!Validator.rules[ruleName]) {
                    throw new Error(`Invalid validation rule: ${ruleName}`);
                }

                if (this.errors[field]) {
                    break;
                }

                const validationError = Validator.rules[ruleName](
                    fieldValue,
                    ruleArgs,
                    this.data
                );

                if (validationError) {
                    this.addError(field, validationError);
                    break;
                }
            }
        }

        return Object.keys(this.errors).length === 0;
    }

    addError(field, message) {
        if (message.includes("_")) {
            message = message.split("_").join(" ");
        }

        if (!this.errors[field]) {
            this.errors[field] = [];
        }

        if (message && !this.errors[field].includes(message)) {
            this.errors[field].push(message);
        }
    }
}

Validator.rules = {
    required(value) {
        return value === undefined || value === null || value.trim() === ""
            ? "This field is required"
            : null;
    },

    nullable(value) {
        return value === undefined || value === null ? null : null; // Always passes
    },

    numeric(value) {
        if (value !== null && value !== "" && (isNaN(value) || value === "")) {
            return "Please enter a valid number";
        }
        return null;
    },

    string(value) {
        return value === null || value === "" || (typeof value === 'string' && isNaN(value)) 
            ? null 
            : "This field must be a string";
    },    

    email(value) {
        return /^\S+@\S+\.\S+$/.test(value)
            ? null
            : "Please enter a valid email address";
    },

    date(value) {
        // Allow empty value, null, or undefined
        if (value === null || value === "") return null;
    
        const dateObject = new Date(value);
        return !isNaN(dateObject.getTime()) ? null : "Please enter a valid date";
    },

    min(value, minLength) {
        if (value !== null && value !== "") {
            if (typeof value === 'string' || typeof value === 'number') {
                if (typeof value === 'string') {
                    const strValue = value.trim();
                    if (strValue.length >= parseInt(minLength, 10)) {
                        return null;
                    } else {
                        return `Please enter at least ${minLength} characters`;
                    }
                } else if (typeof value === 'number') {
                    if (value >= parseInt(minLength, 10)) {
                        return null;
                    } else {
                        return `Value must be at least ${minLength}`;
                    }
                }
            }
        }
        return null;
    },

    max(value, maxLength) {
        if (value !== null && value !== "") {
            if (typeof value === 'string' || typeof value === 'number') {
                if (typeof value === 'string') {
                    const strValue = value.trim();
                    if (strValue.length <= parseInt(maxLength, 10)) {
                        return null;
                    } else {
                        return `Please enter no more than ${maxLength} characters`;
                    }
                } else if (typeof value === 'number') {
                    if (value <= parseInt(maxLength, 10)) {
                        return null;
                    } else {
                        return `Value must be no more than ${maxLength}`;
                    }
                }
            }
        }
        return null;
    },

    match(value, [fieldName], data) {
        const otherValue = data[fieldName];
        return value === otherValue
            ? null
            : `This field should match the ${fieldName}`;
    },

    boolean(value) {
        if (value === null || value === "") return null;
        if (typeof value === 'boolean') return null;
        if (typeof value === 'string') {
            return value.toLowerCase() === 'on' ? null : 'This field must be a boolean';
        }
        return 'This field must be a boolean';
    }    
};
