export function validateImageFile(file) {
    return new Promise((resolve, reject) => {
        const allowedExtensions = ['.png', '.jpg', '.jpeg', '.webp'];

        const extension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
        if (!allowedExtensions.includes(extension)) {
            reject(false)
        }

        if (file.size > 1048576) {
            reject(false);
        }

        const reader = new FileReader()

        reader.onload = (event) => {
            const arrayBuffer = event.target.result
            if (arrayBuffer) {
                const uint8Array = new Uint8Array(arrayBuffer);
                const headerBytes = uint8Array.subarray(0, 10);
                const header = new DataView(headerBytes.buffer);

                try {

                    const isPng = header.getUint32(0) === 0x89504E47 && header.getUint32(4) === 0x0D0A1A0A;
                    const isJpeg = header.getUint16(0) === 0xFFD8;
                    const isJpg = header.getUint32(0) === 0xFFD8FFE0 && header.getUint32(6) === 0x4A464946;
                    const isWebP = header.getUint32(0) === 0x52494646 && header.getUint32(8) === 0x57454250;

                    if (isPng || isJpeg || isJpg || isWebP) {
                        resolve(true)
                    } else {
                        reject(false)
                    }

                } catch {
                    reject(false)
                }

            } else {
                reject(false)
            }
        }
        reader.readAsArrayBuffer(file)
    });
}